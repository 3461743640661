<template>
  <!--解决行业发展痛点-->
  <div class="zj_passSolution">
    <div class="cnname flexcc font16rem black wow bounceIn"
    data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">{{$t("解决行业发展痛点")}}</div>
    <div class="serveSlide flexlraround">
      <div class="adv_part wow" v-for="item in solutionData" :key="item.index"
      :class="item.index % 2 != 0 ? 'bounceInDown' : 'bounceInUp'"
        data-wow-duration="2s"
        data-wow-delay="0s"
        data-wow-offset="10"
        data-wow-iteration="1">
        <div class="pic flexlrc">
          <img :src="item.icon" />
        </div>
        <div class="detail font8rem black2">{{item.detail}}</div>
      </div>
    </div>
  </div>
</template>

<script>
//import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_passSolution",
  data() {
    return {
      solutionData: [
        {
          index: 1,
          detail:
            this.$t("多家软件组装拼接、数据不能互通，形成数据孤岛"),
          icon: require("../../assets/Images/ks_td1.png"),
        },
        {
          index: 2,
          detail:
            this.$t("没有货通人通店通，数据价值差"),
          icon: require("../../assets/Images/ks_td2.png"),
        },
        {
          index: 3,
          detail:
            this.$t("可扩展性差，开发速度慢(基本都是定制型)"),
          icon: require("../../assets/Images/ks_td3.png"),
        },
        {
          index: 4,
          detail:
            this.$t("查询数据不实时(移动化水平低)"),
          icon: require("../../assets/Images/ks_td4.png"),
        }
      ],
    };
  },
  watch: {
    '$i18n.locale': function (newVal) {
        this.solutionData= [
        {
          index: 1,
          detail:
            this.$t("多家软件组装拼接、数据不能互通，形成数据孤岛"),
          icon: require("../../assets/Images/ks_td1.png"),
        },
        {
          index: 2,
          detail:
            this.$t("没有货通人通店通，数据价值差"),
          icon: require("../../assets/Images/ks_td2.png"),
        },
        {
          index: 3,
          detail:
            this.$t("可扩展性差，开发速度慢(基本都是定制型)"),
          icon: require("../../assets/Images/ks_td3.png"),
        },
        {
          index: 4,
          detail:
            this.$t("查询数据不实时(移动化水平低)"),
          icon: require("../../assets/Images/ks_td4.png"),
        }
      ]
   }
  },
  mounted() {
    new this.$wow.WOW({ live: true }).init();
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
.zj_passSolution {
  width: 100%;
  height: 100%;
  margin-top: 4rem;
  .cnname {
    width: 100%;
    height: 10%;
  }
  
  .serveSlide {
    width: 80%;
    height: 90%;
    margin: 0 auto;
    .adv_part {
      width: 18%;
      height: 100%;
      .pic {
        width: 100%;
        //height: 30%;
        margin-top: 20%;
        img{
          width: 50%;
        }
      }
      .name {
        width: 100%;
        height: 20%;
        text-align: center;
      }
      .detail {
        width: 80%;
        height: 50%;
        margin: 0 auto;
        line-height: 1.5rem;
        padding-top: 10%;
        text-align: center;
      }
    }
  }
}
</style>
