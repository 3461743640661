<template>
  <!--pass产品优势-->
  <div class="zj_passAdvantages">
    <div
      class="cnname font16rem black wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1"
    >
      {{$t("产品优势")}}
    </div>
    <div
      class="nameline wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1"
    >
      <div class="line"></div>
    </div>
    <div :class="$i18n.locale == 'CN'?'serveSlide flexlraround':'serveSlideEN flexlraround'">
      <div
        class="adv_part box_shadow2 wow"
        v-for="item in productAdv"
        :key="item.index"
        :class="item.index % 2 == 0 ? 'bounceInDown' : 'bounceInUp'"
        data-wow-duration="2s"
        data-wow-delay="0s"
        data-wow-offset="10"
        data-wow-iteration="1"
      >
        <div class="pic flexlrc">
          <img :src="item.icon" />
        </div>
        <div class="name flexcc font12rem black">{{ item.name }}</div>
        <div class="detail font8rem black2">{{ item.detail }}</div>
      </div>
    </div>
  </div>
</template>

<script>
//import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_passAdvantages",
  data() {
    return {
      productAdv: [
        {
          index: 1,
          name: this.$t("开发效率高"),
          detail:
            this.$t("比传统定制开发效率快10倍左右，可省去程序前端页面、后台逻辑编写、程序集成等环节"),
          icon: require("../../assets/Images/product/ks_cp1.png"),
        },
        {
          index: 2,
          name: this.$t("使用门槛低"),
          detail: this.$t("无需浪费专业开发人员成本，只需配置开发的实施顾问即可操作"),
          icon: require("../../assets/Images/product/ks_cp2.png"),
        },
        {
          index: 3,
          name: this.$t("满足客户个性化要求"),
          detail:
            this.$t("该工具可满足客户80%左右的需求，剩余20%个性化定制，充分满足客户业务流程的多样化要求"),
          icon: require("../../assets/Images/product/ks_cp3.png"),
        },
        {
          index: 4,
          name: this.$t("重塑性强"),
          detail: this.$t("功能搭建自由灵活，保证满足零售企业随着市场不断的变化而变化"),
          icon: require("../../assets/Images/product/ks_cp4.png"),
        },
      ],
    };
  },
  watch: {
    '$i18n.locale': function (newVal) {
        this.productAdv= [
        {
          index: 1,
          name: this.$t("开发效率高"),
          detail:
            this.$t("比传统定制开发效率快10倍左右，可省去程序前端页面、后台逻辑编写、程序集成等环节"),
          icon: require("../../assets/Images/product/ks_cp1.png"),
        },
        {
          index: 2,
          name: this.$t("使用门槛低"),
          detail: this.$t("无需浪费专业开发人员成本，只需配置开发的实施顾问即可操作"),
          icon: require("../../assets/Images/product/ks_cp2.png"),
        },
        {
          index: 3,
          name: this.$t("满足客户个性化要求"),
          detail:
            this.$t("该工具可满足客户80%左右的需求，剩余20%个性化定制，充分满足客户业务流程的多样化要求"),
          icon: require("../../assets/Images/product/ks_cp3.png"),
        },
        {
          index: 4,
          name: this.$t("重塑性强"),
          detail: this.$t("功能搭建自由灵活，保证满足零售企业随着市场不断的变化而变化"),
          icon: require("../../assets/Images/product/ks_cp4.png"),
        },
      ]
   }
  },
  mounted() {
    new this.$wow.WOW({ live: true }).init();
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.zj_passAdvantages {
  width: 80%;
  height: 100%;
  margin-left: 10%;
  margin-top: 3rem;
  .cnname {
    width: 100%;
    margin-bottom: 1rem;
  }
  .nameline {
    width: 100%;
    margin-bottom: 1rem;
    .line {
      width: 8rem;
      height: 0.3rem;
      background-color: #2473e3;
      border-radius: 10px;
    }
  }
  .serveSlide {
    width: 100%;
    height: 60%;
    margin-top: 6%;
    .adv_part {
      width: 18%;
      height: 100%;
      .pic {
        width: 100%;
        //height: 30%;
        img {
          width: 100%;
        }
      }
      .name {
        width: 100%;
        height: 20%;
        text-align: center;
      }
      .detail {
        width: 80%;
        height: 50%;
        line-height: 1.5rem;
        margin: 0 auto;
        text-align: center;
      }
    }
  }
   .serveSlideEN {
    width: 100%;
   min-height: 60%;
    margin-top: 6%;
    .adv_part {
      width: 18%;
      height: 100%;
      .pic {
        width: 100%;
        //height: 30%;
        img {
          width: 100%;
        }
      }
      .name {
        width: 100%;
        height: 20%;
        text-align: center;
      }
      .detail {
        width: 80%;
        min-height: 12.6rem;
        line-height: 1.5rem;
        margin: 0 auto;
        text-align: center;
      }
    }
    .adv_part:nth-child(2n) {
      width: 18%;
      height: 100%;
      .pic {
        width: 100%;
        //height: 30%;
        img {
          width: 100%;
        }
      }
      .name {
        width: 100%;
        height: 20%;
        text-align: center;
      }
      .detail {
        width: 80%;
        min-height: 14rem;
        line-height: 1.5rem;
        margin: 0 auto;
        text-align: center;
      }
    }
  }
}
</style>
