<template>
  <!--PASS快速开发平台工具介绍-->
  <div class="zj_passIntroduce">
    <div class="zj_inside flexrow">
      <div class="detail wow bounceInLeft"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <div class="line1 flextbc">
          <img src="../../assets/Images/ks_icon.png" />
        </div>
        <div class="line2 font14rem fontweight black">{{$t('PAAS快速开发平台工具')}}</div>
        <div :class="$i18n.locale == 'CN'?'line3 font8rem black2':'line3EN font8rem black2'">
          {{$t('魔元术PAAS快速开发平台工具，结合魔元术19年零售行业经验沉淀，沉淀了上千种业务逻辑、将行业通用功能模块颗粒化、结合客户发展的策略和解决方案，以拖拉拽的方式，分步快速开发实现，让新零售系统更贴近业务，实现全价值链数据一体化，减少了传统系统的大量接口，数据不一致性，同时实现数据的实时性和整合性，实现分析与业务系统共存。')}}
        </div>
      </div>
      <div class="pic wow bounceInRight"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1">
        <div class="case flexcc">
          <img src="../../assets/Images/product/ks_pic.png" />
        </div>
        <div class="case1 flexcc">
          <img src="../../assets/Images/ks_sd.png" />
        </div>
        <div class="case2 flexcc">
          <img src="../../assets/Images/ks_zhuan.png" />
        </div>
        <div class="case3"></div>
        <div class="case4"></div>
        <div class="case5"></div>
        <div class="case6"></div>
        <div class="case7"></div>
        <div class="case8"></div>
      </div>
    </div>
  </div>
</template>

<script>
//import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_passIntroduce",
  data() {
    return {
      logodata: [],
    };
  },
  watch: {},
  mounted() {
    new this.$wow.WOW({ live: true }).init();
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.zj_passIntroduce {
  width: 100%;
  height: 100%;
  .zj_inside {
    width: 65%;
    height: 100%;
    margin: auto;
    .detail {
      width: 40%;
      height: 95%;
      margin-top: 1%;
      .line1,
      .line2 {
        width: 100%;
        height: 15%;
        img {
          width: 8%;
        }
      }
      .line3 {
        width: 100%;
        height: 70%;
        line-height: 2.5rem;
      }
      .line3EN {
        width: 100%;
        height: 70%;
        line-height: 1.8rem;
      }
    }
    .pic {
      width: 60%;
      height: 100%;
      .case {
        width: 100%;
        height: 100%;
        img {
          width: 80%;
        }
      }
      .case1 {
        width: 10%;
        height: 10%;
        position: relative;
        margin-left: 54%;
        margin-top: -49%;
        animation: pass1 3s infinite linear;
        -webkit-animation: pass1 3s infinite linear;
        img {
          width: 10%;
        }
      }
      .case2 {
        width: 10%;
        height: 10%;
        position: relative;
        margin-left: 26.5%;
        margin-top: 5.5%;
        animation: pass2 3s infinite linear;
        -webkit-animation: pass2 3s infinite linear;
        img {
          width: 30%;
          transform: skew(-50deg, 35deg);
        }
      }
      .case3{
        width: 0.4rem;
        height: 0.4rem;
        position: relative;
            margin-left: 58%;
       margin-top: -22%;
        border-radius: 100px;
        background-color: rgb(255, 169, 20);
        animation: pass3 3s infinite linear;
        -webkit-animation: pass3 3s infinite linear;
      }
      .case4{
        width: 0.4rem;
        height: 0.4rem;
        position: relative;
            margin-left: 58%;
       margin-top: -3%;
        border-radius: 100px;
        background-color: rgba(255, 169, 20,0.5);
        animation: pass4 3s infinite linear;
        -webkit-animation: pass4 3s infinite linear;
      }
      .case5{
        width: 0.4rem;
        height: 0.4rem;
        position: relative;
            margin-left: 58%;
       margin-top: -3%;
        border-radius: 100px;
        background-color: rgba(255, 169, 20,0.2);
        animation: pass5 3s infinite linear;
        -webkit-animation: pass5 3s infinite linear;
      }
      .case6{
        width: 0.4rem;
        height: 0.4rem;
        position: relative;
            margin-left: 61%;
       margin-top: 5%;
        border-radius: 100px;
        background-color:rgb(255, 61, 114,0.2);
        animation: pass5 3s infinite linear;
        -webkit-animation: pass5 3s infinite linear;
      }
      .case7{
        width: 0.4rem;
        height: 0.4rem;
        position: relative;
            margin-left: 61%;
       margin-top: -3%;
        border-radius: 100px;
        background-color: rgba(255, 61, 114,0.5);
        animation: pass4 3s infinite linear;
        -webkit-animation: pass4 3s infinite linear;
      }
      .case8{
        width: 0.4rem;
        height: 0.4rem;
        position: relative;
            margin-left: 61%;
       margin-top: -3%;
        border-radius: 100px;
        background-color: rgba(255, 61, 114,1);
        animation: pass3 3s infinite linear;
        -webkit-animation: pass3 3s infinite linear;
      }
    }
  }
  
  @keyframes pass5 {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes pass4 {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
   @keyframes pass3 {
    0% {
      opacity: 1;
    }
    25% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    75% {
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes pass2 {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes pass1 {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
</style>
