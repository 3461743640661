<template>
  <!--PASS快速开发平台工具 3个值-->
  <div class="zj_passData flexrow flexlraround">
    <div
      class="passd_part flexrow wow bounceIn"
      v-for="item in passData"
      :key="item.index"
      :class="item.index == 1 ? 'marl15' : 'marl0'"
      data-wow-duration="2s"
      data-wow-delay="0s"
      data-wow-offset="10"
      data-wow-iteration="1"
    >
      <div class="pic flexcc">
        <img :src="item.icon" />
      </div>
      <div class="name flextbc black font12rem">{{ item.name }}</div>
    </div>
  </div>
</template>

<script>
//import {WOW} from 'wowjs'
import Swiper from "swiper";
export default {
  name: "zj_passData",
  data() {
    return {
      passData: [
        {
          index: 1,
          name: this.$t("超过600个参数开发"),
          icon: require("../../assets/Images/ks_cs.png"),
        },
        {
          index: 2,
          name: this.$t("实现需求90%以上"),
          icon: require("../../assets/Images/ks_xq.png"),
        },
        {
          index: 3,
          name: this.$t("10倍开发效率"),
          icon: require("../../assets/Images/ks_xl.png"),
        },
      ],
    };
  },
  watch: {
    '$i18n.locale': function (newVal) {
       this.passData = [
        {
          index: 1,
          name: this.$t("超过600个参数开发"),
          icon: require("../../assets/Images/ks_cs.png"),
        },
        {
          index: 2,
          name: this.$t("实现需求90%以上"),
          icon: require("../../assets/Images/ks_xq.png"),
        },
        {
          index: 3,
          name: this.$t("10倍开发效率"),
          icon: require("../../assets/Images/ks_xl.png"),
        },
      ]
   }
  },
  mounted() {
    new this.$wow.WOW({ live: true }).init();
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.zj_passData {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  .marl15 {
    margin-left: 15%;
  }
  .marl0 {
    margin-left: 0%;
  }
  .passd_part {
    width: 30%;
    height: 100%;
    .pic {
      width: 25%;
      height: 100%;
      img {
        width: 100%;
      }
    }
    .name {
      width: 70%;
      height: 100%;
    }
  }
}
</style>
